<template>
  <section id="securities">
    <b-overlay :show="isLoading">
      <b-card no-body>
        <div class="m-2">
          <!-- Header -->
          <b-row>
            <b-col
              cols="12"
              md="8"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!-- Security type -->
              <b-form-group>
                <b-form-radio-group
                  v-model="secGroupFilter"
                  :options="secGroupOptions"
                  name="security-group"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <div class="d-flex align-items-center justify-content-end">
                <!-- Search -->
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                  size="sm"
                />
                <!-- Closed -->
                <v-select
                  v-model="isTradedFilter"
                  :options="isTradedOptions"
                  :reduce="(status) => status.value"
                  :searchable="false"
                  class="transactions-filter-select select-size-sm"
                  placeholder="Статус"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Table -->
        <b-table
          class="position-relative"
          responsive
          show-empty
          small
          hover
          empty-text="Ценные бумаги отсутствуют"
          primary-key="id"
          ref="refSecurities"
          :fields="fields"
          :items="loadSecurities"
        >
        </b-table>

        <!-- Footer -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Qnt desc -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Показано с {{ qndDesc.from }} по {{ qndDesc.to }} из {{ qndDesc.of }} записей</span
              >
            </b-col>

            <!-- Paginanion -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import vSelect from "vue-select";
import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { debouncedWatch } from "@vueuse/core";

import { securities } from "@/api/investments";
import { asCurrency } from "@/utils/formatter";

export default {
  name: "SecuritiesTable",
  components: {
    vSelect,
  },
  setup(props, context) {
    const isLoading = ref(false);
    const toast = useToast();

    // Parametres
    const totalRows = ref(0);
    const currentPage = ref(1);
    const perPage = ref(20);
    const secGroupFilter = ref(null);
    const searchQuery = ref(null);
    const isTradedFilter = ref(true);

    const secGroupOptions = [
      { text: "Все", value: null },
      { text: "Акции", value: "shares" },
      { text: "Облигации", value: "bonds" },
      { text: "ETF", value: "etfs" },
    ];
    const isTradedOptions = [
      { label: "Открыта продажа", value: true },
      { label: "Закрыта продажа", value: false },
    ];

    // Table
    const refSecurities = ref(null);
    const fields = [
      {
        key: "name",
        label: "Название",
      },
      {
        key: "code",
        label: "Тикер",
      },
      {
        key: "groupName",
        label: "Тип",
      },
      {
        key: "companyName",
        label: "Эмитент",
      },
      {
        key: "price",
        label: "Текущая цена",
        formatter: (val, key, item) => asCurrency(val, item.currencyCode),
      },
    ];
    const loadSecurities = async () => {
      isLoading.value = true;
      try {
        const params = {
          limit: perPage.value,
          skip: (currentPage.value - 1) * perPage.value,
          security_group: secGroupFilter.value,
          q: searchQuery.value,
          is_traided: isTradedFilter.value,
        };
        const { result, total } = await securities(params);
        totalRows.value = total;
        return result;
      } catch (err) {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Ошибка загрузки данных",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Перезагрузите страницу",
          },
        });
        throw err;
      } finally {
        isLoading.value = false;
      }
    };
    const reloadData = () => {
      refSecurities.value.refresh();
    };
    watch([currentPage], () => {
      reloadData();
    });
    watch([perPage, isTradedFilter, secGroupFilter], () => {
      currentPage.value = 1;
      reloadData();
    });
    debouncedWatch(searchQuery, () => reloadData(), { debounce: 700 });

    const qndDesc = computed(() => {
      const localItemsCount = refSecurities.value ? refSecurities.value.localItems.length : 0;
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRows.value,
      };
    });

    const onRowSelected = (items) => {
      if (!items.length) return;

      const row = items[0];
      // const secGroupCode = row.sec_group_code;
      const secCode = row.sec_code;

      context.emit("selectRow", secCode);
    };

    return {
      isLoading,
      fields,
      refSecurities,
      loadSecurities,

      totalRows,
      currentPage,
      perPage,
      qndDesc,
      secGroupFilter,
      secGroupOptions,
      isTradedFilter,
      isTradedOptions,

      searchQuery,
      onRowSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.transactions-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
