<template>
  <b-card-actions title="Новая ценная бумага" action-collapse :collapsed="true">
    <b-row>
      <b-col sm="4">
        <validation-observer ref="addSecurityFormRef" v-slot="{ passes }">
          <b-form @submit.prevent="passes(searchSecurity)">
            <b-row>
              <b-col>
                <!-- security code -->
                <b-form-group label="Код ценной бумаги" label-for="secCode">
                  <input-with-validation
                    id="secCode"
                    v-model="form.code"
                    rules="required"
                    vid="code"
                    label="Код ценной бумаги"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button variant="primary" type="submit" size="sm">Найти</b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <template v-if="secData">
        <b-col sm="8">
          <span class="d-flex" v-for="(value, name, idx) in secData" :key="idx">
            {{ name }} - {{ value }}
          </span>
          <b-form-group label="Вид актива" label-for="securityGroup">
            <select-with-validation
              id="securityGroup"
              v-model="securityGroup"
              :options="$store.getters['investments/securityGroupList']"
              label="name"
              :reduce="(securityGroup) => securityGroup.value"
              rules="required"
              :clearable="false"
              :searchable="false"
              class="select-size-sm"
              vid="securityGroup"
            />
          </b-form-group>
          <b-button variant="primary" type="submit" size="sm" @click.stop="addSecurity"
            >Добавить</b-button
          >
        </b-col>
      </template>
    </b-row>
  </b-card-actions>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import SelectWithValidation from "@/components/inputs/SelectWithValidation.vue";
import InputWithValidation from "@/components/inputs/InputWithValidation.vue";
import BCardActions from "@/@core/components/b-card-actions/BCardActions.vue";

export default {
  name: "SecurityAddForm",
  components: {
    BCardActions,
    ValidationObserver,
    SelectWithValidation,
    InputWithValidation,
  },
  data() {
    return {
      form: {
        code: null,
      },
      securityGroup: "shares",
      secData: null,
    };
  },
  methods: {
    async searchSecurity() {
      this.secData = null;
      const success = await this.$refs.addSecurityFormRef.validate();
      if (!success) {
        return;
      }
      try {
        this.secData = await this.$store.dispatch("investments/loadSecurityData", {
          data: this.form,
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ошибка",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: err.response.data.detail,
          },
        });
        console.log(err);
      }
    },
    async addSecurity() {
      try {
        const result = await this.$store.dispatch("investments/addSecurity", {
          securityGroup: this.securityGroup,
          data: this.secData,
        });

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Бумага добавлена",
            icon: "ThumbsUpIcon",
            variant: "success",
            text: result.name,
          },
        });
        this.secData = null;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ошибка",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: err.response.data.detail,
          },
        });
        console.log(err);
      }
    },
  },
};
</script>

<style></style>
