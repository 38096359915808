<template>
  <section>
    <security-add-form v-if="$can('create', 'Security')" />
    <securities-table @selectRow="selectSecurity" />
  </section>
</template>

<script>
import SecurityAddForm from "@/components/investments/securities/SecurityAddForm.vue";
import SecuritiesTable from "@/components/investments/securities/SecuritiesTable.vue";

export default {
  name: "Securities",
  components: {
    SecurityAddForm,
    SecuritiesTable,
  },
  data() {
    return {
      selectSecCode: null,
    };
  },
  methods: {
    selectSecurity(secCode) {
      this.$router.push({ name: "inv-security", params: { code: secCode } });
    },
  },
};
</script>

<style></style>
